.react-autosuggest__container {
    position: relative;
}

.react-autosuggest__input {
    width: 240px;
    height: 30px;
    padding: 10px 20px;
    font-family: Helvetica, sans-serif;
    font-weight: 300;
    font-size: 16px;
    border: 1px solid #aaa;
    border-radius: 4px;
}

.react-autosuggest__input--focused {
    outline: none;
}

.react-autosuggest__input--open {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
    display: none;
}

.react-autosuggest__suggestions-container--open {
    display: block;
    position: absolute;
    top: 51px;
    width: 280px;
    border: 1px solid #aaa;
    background-color: #fff;
    font-family: Helvetica, sans-serif;
    font-weight: 300;
    font-size: 16px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    z-index: 2;
}

.react-autosuggest__suggestions-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

.react-autosuggest__suggestion {
    cursor: pointer;
    padding: 10px 20px;
}

.react-autosuggest__suggestion--highlighted {
    background-color: #ddd;
}

.custom_select_input .rbt-input-hint-container .rbt-input-main {
    /*border-bottom-left-radius: 17px;
    border-bottom-right-radius: 18px;
    border-top-left-radius: 18px;
    border-top-right-radius: 18px;*/
    border-color: #eeeeee;
    border-radius: 21px;
    margin-top: 0px;
    height: 42px;
    background: rgba(0, 0, 0, 0);
    width: 100%;
}

.custom_select_input .rbt-input-hint-container .rbt-input-hint {
    display: none;
}

.custom_select_input .rbt-input-hint-container .rbt-input-main:focus {
    border: none;
    box-shadow: none;
}

.rbt-imput-main {
    margin-left: 0px;
}

/*style*/